// src/router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { NavMenuApi, GetKnowleageInfoApi, QueryChatAssistantApi } from '@/request/api'

// 告诉 Vue 使用 VueRouter
Vue.use(VueRouter)

const routes = [ // ...其他路由规则
    {
        path: "/login",
        name: 'login',
        component: () =>
            import ( /**" login" */ '../views/logion/UserLogin.vue')
    },
    {
        path: "*",
        component: () =>
            import ( /**" 404" */ '../views/Error_404.vue')
    }, {
        path: '/',
        name: 'mainlayout',

        component: () =>
            import ( /**" mainlayout" */ '../views/layout/MainLayout.vue'),
        redirect: "/home",
        children: [{
                path: "/home",/**jumpSource 跳转页面类型: 1 登录界面跳转*/
                component: () =>
                    // import ( /**" HomeView" */ '../views/HomeView.vue'),
                    // import ( /**" HomeView" */ '../views/apps/AppListTop.vue'),
                    import ( /**" HomeView" */ '../views/apps/AppListTopAll.vue'),
                meta: {
                    titles: ['应用中心']
                }
            }
            // , {
            //     path: "/homeAll",
            //     component: () =>
            //         // import ( /**" HomeView" */ '../views/HomeView.vue'),
            //         import ( /**" HomeView" */ '../views/apps/AppListTopAll.vue'),
            //     meta: {
            //         titles: ['应用中心']
            //     }
            // }
            , {
                path: "/org/org/info",
                component: () =>
                    import ( /**" jigou" */ '../views/orgin/deptAdhocracy.vue'),
                meta: {
                    titles: ['机构信息']
                }

            }, {
                path: "/GrProfile",
                meta: { titles: ["个人中心"] },
                component: () =>
                    import ( /**" GrProfile" */ '../views/GrProfile.vue')
            }, {
                path: "/createKnow/:id?/:statue?/:time?",
                name: "createKnow",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/CreateKnowlegePanel.vue'),
                meta: {
                    titles: ['创建知识库']
                },
                // beforeEnter: async(to, from, next) => {
                //     const id = to.params.id
                //     console.log('id:', id);
                //     if (id) {
                //         to.meta.titles = ['上传文件'];
                //     } else {
                //         to.meta.titles = ['创建知识库'];
                //     }
                //     next();
                // }

            }
			, {
			    path: "/Know/StepExternal",
			    component: () =>
			        import ( /**" HomeView" */ '../views/kbm/StepExternal.vue'),
			    meta: {
			        titles: ['连接外部知识库']
			    }
			
			}
			
			, {
                path: "/recallTest/:id?",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/RecallTest.vue'),
                meta: {
                    titles: ['召回测试']
                }

            }, {
                path: "/Segments/:dataset_id/:document_id",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/SegmentsPanelPchildren.vue'),
                meta: {
                    titles: ['分段管理']
                }

            }, {
                path: "/SetSegment/:dataset_id?/:document_id?/:upload_file_id?",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/SetSegment.vue'),
                meta: {
                    titles: ['分段设置']
                }

            }, {
                path: "/SetSegmentChild/:dataset_id?/:document_id?/:upload_file_id?",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/SetSegmentChild.vue'),
                meta: {
                    titles: ['分段设置']
                }

            }, {
                path: "/dm/:id?/:statue?",
                component: () =>
                    import ( /**" HomeView" */ '../views/consumer/DocConsumer.vue'),
                meta: {
                    titles: ['AI知识库管理', '我的知识库', '文档管理'],
                    meauPath:"/dataset/me",
                },
                // beforeEnter: async(to, from, next) => {
                //     const id = to.params.id
                //     if (id) {
                //         // 查询知识库名称
                //         const GetKnowleageInfoApiRes = await GetKnowleageInfoApi({
                //             id: id
                //         });
                //         console.log('GetKnowleageInfoApiRes:', JSON.stringify(GetKnowleageInfoApiRes))
                //         if (!GetKnowleageInfoApiRes && !GetKnowleageInfoApiRes.data) {
                //             return;
                //         }

                //         console.log('to.meta.titles[to.meta.titles.length - 1]', to.meta.titles[to.meta.titles.length - 1])
                //         to.meta.titles = ['AI知识库管理', '知识库管理', '文档管理', GetKnowleageInfoApiRes.data.name]
                //     }
                //     next();
                // }

            }, 
            {
                path: "/dataset/management",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/KnowledgePanel.vue'),
                meta: {
                    titles: ['AI知识库管理', '知识库管理']
                }
            }, {
                path: "/dataset/me",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/KnowledgePanelMe.vue'),
                meta: {
                    titles: ['AI知识库管理', '我的知识库']
                }
            }, {
                path: "/dataset/public",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/KnowledgePanelPublic.vue'),
                meta: {
                    titles: ['AI知识库管理', '公共知识库']
                }
            }, {
                path: "/dataset/share",
                component: () =>
                    import ( /**" HomeView" */ '../views/kbm/KnowledgePanelShare.vue'),
                meta: {
                    titles: ['AI知识库管理', '共享知识库']
                }
            }, {
                path: "/agentManagement/apps/:mode",
                component: () =>
                    import ( /**" HomeView" */ '../views/apps/AppList.vue'),
                meta: {
                    titles: ['智能体管理', '问答工作流编排']
                }
            }, {
                path: "/agentManagement/agent/:mode",
                component: () =>
                    import ( /**" HomeView" */ '../views/apps/AppList2.vue'),
                meta: {
                    titles: ['智能体管理', 'Agent应用管理']
                }
            }, {
                path: "/agentManagement/workflow/:mode",
                component: () =>
                    import ( /**" HomeView" */ '../views/apps/AppList3.vue'),
                meta: {
                    titles: ['智能体管理', '任务工作流编排']
                }
            }, {
                path: "/prat/:appid?/:type?",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/AIChat.vue'),
                meta: {
                    titles: ['智能体管理', '问答工作流编排', '聊天助手基础编排']
                }
            }, {
                path: "/MuCWFChat/:appid?",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/DifyCWFChat.vue'),
                meta: {
                    titles: ['智能体管理', '问答工作流编排', '聊天助手工作流编排']
                },
                beforeEnter: async(to, from, next) => setTitle(to, from, next)
            }, {
                path: "/MuAIChat/:appid?",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                meta: {
                    titles: ['智能体管理', 'Agent应用管理', '聊天助手基础编排']
                },
                beforeEnter: async(to, from, next) => setTitle(to, from, next)
            }, {
                path: "/MuAgentChat/:appid?",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/DifyAgentChat.vue'),
                meta: {
                    titles: ['智能体管理', 'Agent应用管理', 'Agent应用']
                },
                beforeEnter: async(to, from, next) => setTitle(to, from, next)
            }, {
                path: "/MuWFChat/:appid?",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/DifyWFChat.vue'),
                meta: {
                    titles: ['智能体管理', '任务工作流编排', '工作流']
                },
                beforeEnter: async(to, from, next) => setTitle(to, from, next)
            }, {
                path: "/prat1/:appid?/:type?",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/AIChat1.vue'),
                meta: {
                    titles: ['聊天助手']
                },
                beforeEnter: async(to, from, next) => setTitle(to, from, next)
            }, {
                path: "/prat2/:appid?/:type?",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/AIChat2.vue'),
                meta: {
                    titles: ['Agent应用编排']
                },
                beforeEnter: async(to, from, next) => setTitle(to, from, next)
            }, {
                path: "/MuChat/:appid",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/DifyChat.vue'),
                meta: {
                    titles: ['发布的应用']
                }
            }, {
                path: "/MuWF/:appid",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/agent/DifyWF.vue'),
                meta: {
                    titles: ['发布的应用']
                }
            }, {
                path: "/system/role",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/sys/userRole.vue'),
                meta: {
                    titles: ['权限管理']
                }
            }, {
                path: "/system/meun",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/sys/userMenu.vue'),
                meta: {
                    titles: ['菜单管理']
                }
            }, {
                path: "/system/user",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/sys/loginUser.vue'),
                meta: {
                    titles: ['用户管理']
                }
            }, {
                path: "/log/userLog",
                component: () =>
                    // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                    import ( /**" AIChat" */ '../views/log/userLog.vue'),
                meta: {
                    titles: ['日志管理', '日志信息']
                }
            }
        ],
    }
]

async function setTitle(to, from, next) {
    const appid = to.params.appid
    if (appid) {
        // 查询智能体名称
        const QueryChatAssistantApiRes = await QueryChatAssistantApi({
            id: appid
        });

        if (!QueryChatAssistantApiRes && !QueryChatAssistantApiRes.data) {
            return;
        }

        // to.meta.titles = ['智能体管理', '聊天助手编排', '聊天助手工作流编排', QueryChatAssistantApiRes.data.name]
        to.meta.titles[to.meta.titles.length - 1] = QueryChatAssistantApiRes.data.name
    }
    next();
}

var router = new VueRouter({
    mode: 'history', // 使用 HTML5 History API
    base: process.env.BASE_URL,
    // base: '/aigcweb',
    routes
})

// 路由守卫

// router.beforeEach((to, from, next) => {
//     if (to.path === from.path) {
//         next(false); // 取消导航
//     } else {
//         next(); // 继续导航
//     }
// })

// 查询知识库
async function GetKnowleageInfoApiFunc(id) {
    if (id) {
        const GetKnowleageInfoApiRes = await GetKnowleageInfoApi({
            id: id
        });
        return GetKnowleageInfoApiRes;
    }
    return null
}

router.beforeEach(async(to, from, next) => {
    // 判断是否是文档跳转，是则将当前路由title修改为文档名称
    const toPath = to.path;
    if (toPath && toPath.includes('/dm')) {
        const id = to.params.id
        if (id) {
            // 查询知识库名称
            const GetKnowleageInfoApiRes = await GetKnowleageInfoApiFunc(id);
            if (GetKnowleageInfoApiRes && GetKnowleageInfoApiRes.data) {
                to.meta.titles = ['AI知识库管理', '知识库管理', '文档管理', GetKnowleageInfoApiRes.data.name]
            }
        }
    }
    // 判断是否是创建知识库跳转，还是新增文档跳转
    if (toPath && toPath.includes('/createKnow')) {
        const id = to.params.id
        if (id) {
            // 查询知识库名称
            const GetKnowleageInfoApiRes = await GetKnowleageInfoApiFunc(id);
            // 新增文档
            to.meta.titles = ['上传文件'];
            if (GetKnowleageInfoApiRes && GetKnowleageInfoApiRes.data) {
                to.meta.titles = ['上传文件', GetKnowleageInfoApiRes.data.name];

            }
        } else {
            // 新增知识库
            to.meta.titles = ['创建知识库'];
        }
    }
    // 判断是否是文档跳转，是则将当前路由title修改为文档名称
    // const toPath=to.path;
    // if(toPath&&toPath.includes()){

    // }

    // if (to.path === from.path) {
    //     next(false); // 取消导航
    // }
    // router.currentRoute.meta.titles = ['新标题'];
    // var documentName = to.params.documentName
    // console.log('documentName:', documentName)
    // if (documentName) {
    //     // 执行操作，例如修改标题
    //     // to.meta.titles[to.meta.titles.length - 1] = '新标题';
    //     to.meta.titles[to.meta.titles.length - 1] = documentName
    // }

    const accessToken = localStorage.getItem('edb-access-token')
    const refreshToken = localStorage.getItem('edb-refresh-token')
    const userInfo = store.state.userInfo
        // 1.访问login已登录，跳转到首页
    if (to.path === '/login' && accessToken && refreshToken && userInfo && userInfo.userInfo) {
        next('/home');
        return
    }
    // 2.用户不是登录页面，但未登录，跳转到登录页面
    if (to.path !== '/login' && (!accessToken || !refreshToken || !userInfo || !userInfo.userInfo)) {
        next('/login')
        return
    }

    // 有token，初始数据为0，则加载菜单
    if (userInfo && userInfo.userInfo && accessToken && refreshToken && store.state.userMenuData.menuData.length == 0) {
        let NavMenuApiRes = await NavMenuApi({ userid:userInfo.userInfo.pkid,loginCode: userInfo.userInfo.loginCode })
        if (!NavMenuApiRes || !NavMenuApiRes.data) return;
        NavMenuApiRes = NavMenuApiRes.data
            //拼装 menuData
        let newUserMenuData = [{ title: "应用中心", path: "/", icon: "shouye" }]
        let ret = NavMenuApiRes.menuList.map(item => {
            if (item.children) {
                return {
                    title: item.title,
                    icon: item.icon,
                    path: item.url,
                    children: item.children.map(sitem => {
                        return {
                            title: sitem.title,
                            icon: sitem.icon,
                            path: item.url + "/" + sitem.url
                        }
                    })
                }
            } else {
                return {
                    title: item.title,
                    path: item.url,
                    icon: item.icon
                }
            }

        })
        newUserMenuData = [...newUserMenuData, ...ret];

        //更新数据
        store.commit("userMenuData/changeMenuData", newUserMenuData);
        // 权限
        store.commit("userPermissions/changePermissions", NavMenuApiRes.permissions.join(','))


        //以上生成菜单数据

        //以下生成路由数据
        let newChildrenRoutes = [{
                path: "/home",
                meta: { titles: ["应用中心"] },
                component: () =>
                    import ('../views/HomeView.vue')
            },
            {
                path: "/profile",
                meta: { titles: ["个人中心"] },
                component: () =>
                    import ('../views/GrProfile.vue')
            }
        ]
        NavMenuApiRes.menuList.forEach(item => {
            let ret = item.children.map(sitem => {
                return {
                    path: item.url + "/" + sitem.url,
                    component: () =>
                        // import (`../views${item.url}/${sitem.name}.vue`),
                        import (`../views${item.url}/${sitem.url}.vue`),
                    meta: { titles: [item.title, sitem.title] }
                }
            })
            newChildrenRoutes = [...newChildrenRoutes, ...ret]
        });
        newChildrenRoutes.forEach(item => {
            router.addRoute('mainlayout', item);
        })
            // 重新走一遍,因为第一次走，路由还没完成，还是空的，需要再走一遍
        next(to.path);
        return

    }
    //放行
    next()
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
    return originalPush.call(this, location).catch(err => {
        console.log('err:' + err)
    })
};

export default router