import Vue from 'vue'

//清除浏览器默认样式
import 'reset-css'
import App from './App.vue'
import store from './store';
import router from './router'
import './plugins/element'
import "@/assets/icons/index"
import "@/utils/filters"
import '@/assets/icons';
import '@/styles/global.less'; 
import { isAuth, getTagType, getDicValue, getDicOption } from '@/utils/common'

Vue.prototype.isAuth = isAuth
Vue.prototype.getTagType = getTagType
Vue.prototype.getDicValue = getDicValue
Vue.prototype.getDicOption = getDicOption
Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')