import axios from "axios";
import {
    Message
} from 'element-ui'
import router from "@/router"

const instance = axios.create({
    // baseURL: "/api",
    //测试
    //  baseURL: "http://192.168.3.208:8999",
    // baseURL: "https://ai.wuhan-cloud.com/knowleage",
    baseURL: "http://pt.medicalunion.cn/knowleage",
    //浙江
    // baseURL: "http://129.87.240.39/knowleage",
    timeout: 1000 * 120,
    // 代理的端口
    // proxy: {
    //     port: 8080,
    //     host: '127.0.0.1'
    // },
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

// 请求拦截器
instance.interceptors.request.use(config => {
    const accessToken = localStorage.getItem("edb-access-token")
    // const refresToken = localStorage.getItem("edb-refresh-token")
    if (accessToken && !config.url.endsWith('/login') && !config.url.endsWith('/captcha.jpg')) {
        config.headers["token"] = "bearer " + accessToken;
      
        //验证token是否过期
        const [header, payload, signature] = accessToken.split('.'); // 分割Token
        const payloadObj = JSON.parse(atob(payload)); // 解码Payload部分并转换为对象

        // 检查exp字段是否存在
        if (!payloadObj.exp) {
            //throw new Error('Token does not contain an expiration field.');
            localStorage.removeItem("edb-access-token")
            localStorage.removeItem("edb-refresh-token")
            localStorage.removeItem("edb-userInfo")
            localStorage.removeItem("edb-permissions")
            router.push("/login")
            // 超时提示屏蔽todo:20250317
            /* Message.warning('登录过期，请重新登录');*/
            return config;
        }
        console.log(header + signature)
            // 将exp字段（Unix时间戳）转换为Date对象
        const expirationDate = new Date(payloadObj.exp * 1000);

        // 获取当前时间
        const now = new Date();
        // 比较当前时间和过期时间
        if (now > expirationDate) // 如果当前时间大于过期时间，则Token已过期 
        {
            console.log("已过期" + expirationDate)
                //localStorage.removeItem("edb-authorization-token")
                //router.push("/login")
            localStorage.removeItem("edb-access-token")
            localStorage.removeItem("edb-refresh-token")
            localStorage.removeItem("edb-userInfo")
            localStorage.removeItem("edb-permissions")
            router.push("/login");
            // 超时提示屏蔽todo:20250317
            // Message.warning('登录过期，请重新登录');
            return config;
        } else {
            console.log("" + expirationDate)
                //alert("未过期")
        }
        //
    }
    return config
}, err => {
    return Promise.reject(err)
})



//响应拦截器
instance.interceptors.response.use(res => {

    //  console.log('返回参数res:' + JSON.stringify(res))
    //外部网络判断
    // if (!res_data || res.status != 200 || res_data.code != '0') {
    if (!res || res.status !== 200) {
        // Message.error(statusText.msg || '网络请求错误');
        Message.error(res.statusText || '网络请求错误');
        return false
    }
    const res_data = res.data
        // console.log("响应参数res_data:" + JSON.stringify(res_data))
        //内部数值判断
    if (!res_data || Number(res_data.code) !== 0) {
        // if (!res_data) {
        const codeRes = res_data.code
            // token过期，跳转到login
        if (codeRes === 401) {
            //token过期
            localStorage.removeItem("edb-authorization-token")
            router.push("/login")
        }
        if (res_data.msg === '知智能体不存在') {
            return false
        }

        if (res_data.msg === '知识库不存在') {
            return false
        }
        if (res_data.msg === '知识库中没有文件') {
            return false
        }

        if(res_data.msg === '分段预览请求失败'){
            return false
        }

        if (res_data.msg === '没有日志信息') {
            return false
        }
        

        Message.error(res_data.msg || '网络请求错误');
        return false
    }

    return res_data
}, err => {
    return Promise.reject(err)
})

export default instance