/**
 * 获取uuid
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
}

/**
 * 导出文件
 * @param {string} ymlData 数据体
 * @param {string} fileName 文件名
 * @returns 
 */
export function exportYaml(ymlData, fileName) {
    if (!fileName) {
        console.error("文件名不能为空！")
        return false;
    }
    // 将 JSON 数据转换为 YAML 字符串
    // const yamlStr = json2yaml.stringify(jsonData);

    // 创建一个 Blob 对象
    const blob = new Blob([ymlData], { type: 'text/yaml' });

    // 创建一个下载链接
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName + '.yml'; // 设置下载文件的名称
    link.style.display = 'none'; // 隐藏链接
    document.body.appendChild(link);
    link.click(); // 触发下载
    document.body.removeChild(link); // 移除链接
    window.URL.revokeObjectURL(url); // 释放 URL 对象
    return true;
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'pid') {
    var res = []
    var temp = {}
    for (var i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i]
    }
    for (var k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]]['children']) {
                temp[data[k][pid]]['children'] = []
            }
            if (!temp[data[k][pid]]['_level']) {
                temp[data[k][pid]]['_level'] = 1
            }
            data[k]['_level'] = temp[data[k][pid]]._level + 1
            temp[data[k][pid]]['children'].push(data[k])
        } else {
            res.push(data[k])
        }
    }
    return res
}

/**
 * 树形数据转换(tree-props)
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslateToElementTable(data, id = 'id', pid = 'pid') {
    var res = []
    var temp = {}
    for (var i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i]
    }
    for (var k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]]['children']) {
                temp[data[k][pid]]['children'] = []
            }

            temp[data[k][pid]]['children'].push(data[k])
        } else {
            res.push(data[k])
        }
    }
    return res
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
    return (localStorage.getItem('edb-permissions') || '[]').indexOf(key) !== -1 || false
}

export function getDay(date, num) {
    // 获取前几天的日期
    var day1 = new Date(date);
    day1.setDate(day1.getDate() - num);
    return day1.format("yyyy-MM-dd");
}

Date.prototype.format = function(fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

/**
 * 根据字典值展示不通的el-tag type类型
 */
export function getTagType(value) {
    let data = ['danger', '', 'success', 'info', 'warning', 'success', 'danger', '#', 'info', 'success', '', 'success']
    return data[value]
}

/**
 * 获取缓存中字典
 */
function getAllDicItems() {
    // todo:写死待定
    const dicItems = {
            MODE: [{
                dicCode: 'custom',
                dicValue: '通用'

            }, {
                dicCode: 'hierarchical',
                dicValue: '父子'
            }],
            STATUS: [{
                dicCode: '1',
                dicValue: '正常'

            }, {
                dicCode: '2',
                dicValue: '停用'
            }],
            SALT: [
                {
                dicCode: 'sys',
                dicValue: '系统管理员'

            }, 
            {
                dicCode: 'manage',
                dicValue: '管理员'
            }, {
                dicCode: 'user',
                dicValue: '普通用户'
            }],
            MERCHANT_TYPE: [{
                dicCode: '1',
                dicValue: '系统',
            }, {
                dicCode: '2',
                dicValue: '机构',
            }, {
                dicCode: '3',
                dicValue: '部门',
            }, ],
            MENU_TYPE: [{
                dicCode: '0',
                dicValue: '目录',
            }, {
                dicCode: '1',
                dicValue: '菜单',
            }, {
                dicCode: '2',
                dicValue: '按钮',
            }, ]
        }
        // return JSON.parse(sessionStorage.getItem('dicItems') || '[]')
    return dicItems
}


/**
 * 根据catalogId,获取字典集合，用于el-select,el-radio,el-checkbox UI组件
 */
export function getDicOption(catalogId) {
    var dicItems = getAllDicItems()
    return objToMap(dicItems).get(catalogId)
}


/**
 * 根据catalogId,字典标签获取字典值
 */
export function getDicValue(catalogId, originValue) {
    // debugger
    var dicItems = getAllDicItems()
    var data = objToMap(dicItems).get(catalogId)
    var value = ''
    if (data != null) {
        for (var i = 0; i < data.length; i++) {
            if (String(originValue) === data[i].dicCode) {
                value = data[i].dicValue
            }
        }
    }
    return value
}

/**
 *对象转换为Map
 */
function objToMap(obj) {
    let strMap = new Map()
    for (let k of Object.keys(obj)) {
        strMap.set(k, obj[k])
    }
    return strMap
}


/**
 *  字符串转时间
 * @param dateString yyyy-MM-dd hh:mm:ss
 * @returns {Date}
 */
export function convertDateFromStr(dateString) {
    if (dateString) {
        var arr1 = dateString.split(" ");
        var sdate = arr1[0].split('-');
        var date = new Date(sdate[0], sdate[1] - 1, sdate[2]);
        return date;
    }
}
import CryptoJS from 'crypto-js';
/**
 * 将密码转为hash并转为十六进制字符串
 * 
 * @param {String} passwordInput 
 * @returns 加密数据
 */
export async function encryptPassword(passwordInput) {
    if (passwordInput) {
        /**浏览器api加密，因ip访问会被识别为不安全而禁用，无法使用，代替为CryptoJS */
        // 将文本编码为 UTF-8 字节数组
        // const encoder = new TextEncoder();
        // const data = encoder.encode(passwordInput);
        // // 生成哈希值
        // const hashBuffer = await crypto.subtle.digest('SHA-256', data);

        // // 将哈希值转换为十六进制字符串
        // const hashArray = Array.from(new Uint8Array(hashBuffer));
        // const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

        // 使用 CryptoJS 计算 SHA-256 哈希值
        const hash = CryptoJS.SHA256(passwordInput);

        // 将哈希值转换为十六进制字符串
        const hashHex = hash.toString(CryptoJS.enc.Hex);

        return hashHex;


    }
    return null;
}